.block30 {
  padding: 90px 38px 38px;
  background: var(--color-white-80);

  @include media-max(md) {
    padding: 60px 16px 18px;
  }

  .h1 {
    text-align: center;
  }

  .mySwiper2 .swiper-wrapper {
    align-items: flex-end;
  }

  @include media-min(md) {
    .swiper2-slide {
      width: auto !important;
      margin-right: 0 !important;
    }

    .tooltip {
      z-index: 10;
    }
  }

  @include media-max(md) {
    .swiper .swiper-slide .tooltip {
      display: block !important;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      .tooltip__btn {
        position: absolute;
        z-index: 11;
        right: 20px;
      }
    }

    .swiper .swiper-slide .tooltip-box {
      width: 110% !important;
      height: 115% !important;
      left: -20px;
      top: -20px;
      right: -20px;
      position: absolute;
      z-index: 1;
      background: var(--color-white-50) !important;
      backdrop-filter: blur(5px) !important;
      margin-left: 0 !important;
      display: none;
    }

    .swiper .swiper-slide .active .tooltip-box {
      display: block;
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: white;
      margin-top: calc(30px - var(--swiper-navigation-size)/ 2);
      z-index: 10000;
    }

    .swiper2-slide {
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      position: relative;
      transition-property: transform;
      display: block;

      img {
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }
  }


  .navs {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 0;

    .swipernavs_slider {
      overflow: auto;

      @media(max-width:500px) {
        border-radius: 0;
        margin-left: -28px;
        margin-right: -28px;
      }

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    // @include media-max(md) {
    //   display: block;

    //   .swipernavs_slider {
    //     height: auto;
    //     border: none;
    //     background-color: transparent;
    //     flex-wrap: wrap;
    //     gap: 5px;

    //     li button {
    //       padding: 10px;
    //       background-color: white;
    //       border: 1px solid black;
    //       color: black;

    //       &.active {
    //         background-color: black;
    //         color: white;
    //       }
    //     }
    //   }
    // }
  }

  .swiper {
    padding: 32px 0 0;

    .swiper-slide {
      position: relative;

      height: 44.19vw;
      overflow: hidden;
      max-height: calc(100svh - 300px);
      background-color: transparent;
      background-image: url('../../img/contract_production/s3/bg.png');
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;

      .tooltip {
        position: absolute;
        top: 20px;
        left: 20px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        &.active {
          z-index: 10;

          .tooltip__btn {
            transform: rotate(135deg);
          }
        }

      }

      .tooltip__btn {
        flex: 0 0 auto;

        width: 50px;
        height: 50px;
        border-radius: 25px;

        border: none;
        outline: none;

        background-color: var(--color-text-hover);
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99982 0.181885L6.99982 13.8182M0.181641 7.00007L13.818 7.00007' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 14px 14px;
      }

      .tooltip-box {
        flex: 0 0 auto;
        width: 292px;
        margin-left: 12px;
        padding: 20px 16px;

        border-radius: 15px;
        background: var(--color-white-70);
        backdrop-filter: blur(15px);

        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.2s ease;
      }

      .tooltip.active {
        .tooltip-box {
          transform: scaleY(1);
        }
      }

      .tooltip-cont+.tooltip-cont {
        margin-top: 22px;

        @include media-max(sm) {
          margin-top: 13px;
        }
      }

      .tooltip__title {
        color: var(--color-text-hover);
        font-family: 'Manrope';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;

        @include media-max(sm) {
          font-size: 13px;
          line-height: 18px;
        }
      }

      .tooltip-item {
        color: var(--color-text-main);
        font-family: 'Manrope';
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;

        @include media-max(sm) {
          font-size: 11px;
          line-height: 16px;
        }
      }

      .bold {
        color: var(--color-text-main);
        font-family: 'Manrope';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;

        @include media-max(sm) {
          font-size: 11px;
          line-height: 16px;
        }
      }

      .swiper-slide-gallery {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-end;

        padding-bottom: 3.5vw;
        overflow: hidden;

        .swiper-slide-gallery-wrap {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: flex-end;

          height: 100%;

          overflow-x: auto;
          overflow-y: hidden;

          img {
            display: block;
            width: auto;
            margin-left: 1vw;
            margin-right: 1vw;
            max-height: calc(100svh - 400px);
          }
        }
      }
    }
  }
}


.contract-production-1-1 {
  /* 752 */
  height: 28vw;
  margin-bottom: -0.3vw;
}

.contract-production-1-2 {
  /* 746 */
  height: 27.7vw;
}

.contract-production-1-3 {
  /* 1016 */
  height: 37.8vw;
}

.contract-production-1-4 {
  /* 720 */
  height: 26.8vw;
}

.contract-production-1-5 {
  /* 540 */
  height: 20.1vw;
}

.contract-production-2-1 {
  /* 978 */
  height: 36.4vw;
  margin-bottom: -0.3vw;
}

.contract-production-2-2 {
  /* 892 */
  height: 33.2vw;
}

.contract-production-2-3 {
  /* 790 */
  height: 29.4vw;
  margin-bottom: -1.3vw;
}

.contract-production-2-4 {
  /* 732 */
  height: 27.3vw;
  margin-bottom: -0.5vw;
}

.contract-production-2-5 {
  /* 385 */
  height: 14.3vw;
  margin-bottom: -0.6vw;
}

.contract-production-3-1 {
  /* 678 */
  height: 25.2vw;
}

.contract-production-3-2 {
  /* 900 */
  height: 33.5vw;
}

.contract-production-3-3 {
  /* 962 */
  height: 35.8vw;
}

.contract-production-3-4 {
  /* 902 */
  height: 33.6vw;
  margin-bottom: -0.3vw;
}

.contract-production-4 {
  /* 1012 */
  height: 37.7vw;
  margin-bottom: -2.5vw;
}

.contract-production-5 {
  /* 1012 */
  height: 37.7vw;
  margin-bottom: -3vw;
}