footer.main {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 30px;
  padding-top: 48px!important;
  padding-bottom: 20px!important;

  @include media-max(lg) {
    grid-template-columns: 1fr;
    gap: 14px;
  }

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5000px;
    display: block;
    width: 10000px;
    background: var(--color-white);
    content: "";
  }
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    &:nth-child(1) {
      a.logo {
        display: block;
        margin-top: 5px;
        width: 160px;
        height: 55px;

        @include media-max(lg) {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .desc {
        margin-top: 35px;
        font-weight: 400;
        letter-spacing: -0.4px;

        @include media-max(lg) {
          text-align: center;
          margin-top: 30px;
          font-size: 13px;
          letter-spacing: 0;
        }
      }
      .small { @include media-max(lg) { display: none;} }
    }
    &:nth-child(2) {
      @include media-max(lg) { display: none; }

      ul,
      .small {
        margin-left: -10px;

        @include media-min(xl) { margin-left: 110px; }
      }
      ul {
        columns: 2;
        column-gap: 40px;
        margin-right: 70px;
        letter-spacing: -.4px;

        @include media-min(xl) {
          margin-left: 110px;
          margin-right: 190px;
        }

        li {
          margin-bottom: 8px;
          a {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
    &:nth-child(3) {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @include media-max(lg) { align-items: center; }

      a {
        display: inline-block;
        &.h2 {
          font-weight: 500;
          color: var(--color-text-hover);
          font-size: 29px;

          @include media-max(lg) { font-size: 23px; }

          &:hover { color: var(--color-text-main); }
          &.email { margin: 8px 0 0; }
        }
        &.created {
          display: flex;
          align-items: flex-end;
          margin: auto 0 0;
          &:hover {
            svg {
              use {
                fill: var(--color-text-hover);
              }
            }
          }
          span {
            display: block;
            padding: 50px 0 0;
            white-space: nowrap;
          }
          svg {
            width: 121px;
            height: 14px;
            margin: 0 0 4px 3px;
            use {
              fill: var(--color-created);
            }
          }
        }
      }
      ul {
        display: grid;
        grid-template-columns: repeat(3, 48px);
        gap: 16px;
        width: auto;
        padding: 30px 0;
        li {
          height: 48px;
          a {
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid var(--color-socials);
            border-radius: 10px;
            &:hover {
              border-color: var(--color-text-hover);
              svg {
                use {
                  fill: var(--color-text-hover);
                }
              }
            }
            svg {
              use {
                fill: var(--color-socials);
              }
            }
          }
        }
      }
    }
    .small {
      margin: auto 0 0;
      font-size: 11px;
      line-height: 18px;
    }
  }
}

.copy-mobile {
  @include media-min(lg) { display: none; }
}
