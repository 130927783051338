.block22 {
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 30px 0 0;

    @include media-max(lg) { grid-template-columns: repeat(2, 1fr); }
    @include media-max(md) { display: block; }

    li {
      @include media-max(md) { margin-bottom: 16px; }

      a {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 30px 24px;
        background: var(--color-white);

        @include media-max(md) { padding: 20px 16px; }

        &:hover {
          transform: scale(.97);
        }
        .date {
          font-weight: 300;
        }
        h3 {
          padding: 22px 0 70px;

          @include media-max(md) { padding: 17px 0 32px; }
        }
        img {
          margin: auto 0 0;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    padding: 40px 0 0;
  }
}
