.block23 {
  ul.swipernavs_slider {
    position: absolute;
    top: 0;
    right: 0;
  }
  .swiper {
    padding: 26px 0 0;
    .swiper-slide {
      display: grid;
      grid-template-columns: 42% calc(58% - 16px);
      gap: 16px;

      @include media-max(md) { display: block; }

      .item {
        overflow: hidden;
        background: var(--color-white-80);
        &:first-child {
          padding: 40px 30px;

          @include media-max(md) { margin-bottom: 16px; }

          .h3 { padding: 0 0 40px; }
          .address {
            padding: 10px 0 0;
            color: var(--color-text-hover);
          }
          ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px 16px;
            padding: 40px 0 0;
            li {
              .desc {
                padding: 0;
                line-height: 20px;
              }
              .value {
                padding: 10px 0 0;
                font-weight: 800;
              }
            }
          }
        }
        &:last-child {
          .map {
            height: 100%;
            iframe {
              display: block;
              width: 100%;
              height: 100%;
              @include media-max(lg) { min-height: 350px; }
            }
          }
        }
      }
    }
  }
}
