.block4 {
  margin: 50px 0 0;
  position: relative;
  z-index: 1;

  @include media-max(lg) { margin-top: 30px; }

  .swiper {
    .swiper-wrapper {
      align-items: stretch;
      .swiper-slide {
        height: auto;
        padding: 38px 20px 42px;
        border: 1px solid var(--color-black-10);
        background: var(--color-white-60);
        transition-property: all;

        @include media-max(xl) { padding-bottom: 60px; }
        @include media-max(lg) {
          padding: 27px 16px 84px;
          width: 80%;
        }

        &:hover {
          border-color: var(--color-white);
          background: var(--color-white);
        }
        img {
          width: 67px;
          height: 67px;
        }
        h3 {
          margin: 32px 10px 0 0;
          font-weight: 500;
        }
        .desc {
          padding: 12px 0 0;
          letter-spacing: -0.5px;
        }
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0 0;

      @include media-max(lg) { padding-top: 20px; }
    }
  }
}
