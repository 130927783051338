.block5 {
  margin: 110px 0 0;

  @include media-max(lg) { margin-top: 40px; }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    grid-template-areas: "one three" "two three";

    @include media-max(lg) {
      grid-template-columns: 1fr;
      grid-template-areas: "two" "one" "three";
    }

    li {
      &:nth-child(1) { grid-area: one; }
      &:nth-child(2) { grid-area: two; }
      &:nth-child(3) {
        grid-area: three;
        a span {
          left: 17px;
          top: 5px;

          @include media-max(md) {
            left: 0;
            top: 0;
          }
        }
      }
      a {
        position: relative;
        display: block;
        transition: transform .7s;
        overflow: hidden;
        &:hover {
          transform: scale(.97);
          img {
            transform: scale(1.12);
          }
        }
        img {
          transition: transform .7s;
          object-fit: cover;
        }
        span {
          font-size: 29px;
          line-height: 1.4;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          padding: 48px 32px;
          color: var(--color-text-main);

          @include media-max(lg) {
            font-size: 22px;
            padding: 20px 18px;
          }
        }
      }
    }
  }
}
