.block35 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  z-index: 1;

  @include media-max(md) {
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px !important;
  }

  .item {
    width: 50%;
    aspect-ratio: 1 / .75;

    @include media-max(md) { width: 100%; }

    &:first-child {
      position: relative;

      @include media-max(md) { margin-bottom: 60px; }

      .swiper {
        &.main {
          width: 100%;
          height: 100%;
          background: var(--color-white-80);
          .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--color-white);
            img {
              width: auto;
              max-height: 80%;

              @include media-max(md) { max-height: 304px; }
            }
          }
        }
        &.thumb {
          position: absolute;
          top: 50%;
          left: 40px;
          height: calc(100% - 170px);
          transform: translateY(-50%);

          @include media-max(md) {
            top: 105%;
            left: 18px;
            height: auto;
            transform: none;
            width: calc(100% - 34px);
          }
          .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 70px;
            border: 1px solid var(--color-text-gray);
            border-radius: 6px;
            background: var(--color-white);
            opacity: .5;
            cursor: pointer;
            &.active {
              opacity: 1;
            }
            img {
              width: auto;
              max-height: 80%;
            }
          }
        }
      }
      .navs {
        position: absolute;
        top: 0;
        left: 56px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 0;
        height: 100%;
        padding: 46px 0;
        z-index: 1;

        @include media-max(md) { display: none; }

        button {
          width: 38px;
          height: 38px;
          &:hover {
            svg {
              use {
                stroke: var(--color-text-hover);
              }
            }
          }
          &:first-child {
            svg {
              transform: rotate(180deg);
            }
          }
          &.swiper-button-disabled {
            opacity: 0;
            visibility: hidden;
          }
          svg {
            use {
              stroke: var(--color-text-main);
              stroke-width: 2;
            }
          }
        }
      }
    }
    &:last-child {
      .type {
        padding: 10px 0 0;
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
      }
      .article {
        padding: 20px 0 0;
        font-size: 24px;
        font-weight: 500;
        color: var(--color-text-gray);
        line-height: 32px;

        @include media-max(md) { font-size: 17px; }
      }
      ul.params {
        padding: 30px 0 0;
        li {
          padding: 0 0 10px;
          &:last-child {
            padding: 0;
          }
          .value {
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }
      .packaging {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 30px 0 0;
        .value {
          display: flex;
          align-items: center;
          height: 36px;
          padding: 0 20px;
          border: 1px solid var(--color-text-hover);
          border-radius: 18px;
          font-size: 14px;
          font-weight: 300;
          color: var(--color-text-hover);
          line-height: 24px;
        }
      }
      .orders {
        padding: 30px 0 0;
        max-height: 0;
        overflow: hidden;

        &.active { max-height: 500px; }

        .desc {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        ul {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 6px;
          max-width: 396px;
          padding: 12px 0 0;
          li {
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              border-radius: 7px;
              background: var(--color-white-80);
              img {
                max-width: 66%;
              }
            }
          }
        }
      }
      button.btn {
        margin: 30px 0 0;

        @include media-max(md) {
          font-size: 12px;
          margin-left: 20px;
          padding-left: 28px;
          padding-right: 28px;
        }
      }
    }
  }
}
