html,
body {
  background: var(--color-bg);
  font-family: Manrope, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: var(--color-text-main);
  line-height: 28px;

  &.overflow {
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
    transition: all .35s ease;

    &:before,
    &:after {
      transition: all .35s ease;
    }
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  img,
  svg {
    display: block;
    width: 100%;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: none;
    stroke: none;
  }

  a {
    color: var(--color-text-main);
    text-decoration: none;

    &:hover {
      color: var(--color-text-hover);
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    border: 0;
    background: var(--color-transparent);

    &:hover {
      color: var(--color-text-hover);
    }

    cursor: pointer;
  }

  b {
    font-weight: 700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
    letter-spacing: -1px;
  }

  h1,
  .h1 {
    font-size: 44px;
    font-weight: 600;
    line-height: 60px;

    @include media-max(lg) {
      font-size: 29px;
      line-height: 43px;
    }
  }

  h2,
  .h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }

  h3,
  .h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;

    @include media-max(lg) {
      font-size: 19px;
      line-height: 25px;
    }
  }

  .desc {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }

  form {
    label {
      display: block;
      padding: 0 0 10px;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;

      @include media-max(md) {
        font-size: 12px;
        padding-bottom: 12px;
      }
    }

    textarea,
    input {
      display: block;
      max-width: 100%;
      min-width: 100%;
      padding: 14px 20px;
      border: 1px solid var(--color-white);
      border-radius: 10px;
      background: var(--color-white);
      font-size: 14px;
      line-height: 24px;
      outline: none;
      box-sizing: border-box;

      &::placeholder {
        color: var(--color-text-gray);
      }

      &.ready {
        border-color: var(--color-text-hover) !important;
      }

      &.error {
        border-color: var(--color-error) !important;
      }
    }

    input {
      height: 50px;

      &[type="checkbox"],
      &[type="radio"] {
        display: none;

        &:checked {
          &+label {
            &:before {
              border-color: var(--color-text-hover);
              background: var(--color-text-hover);
            }

            &:after {
              transform: rotate(45deg) scaleY(1);
            }
          }
        }

        &+label {
          position: relative;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          &:before,
          &:after {
            display: block;
            content: "";
          }

          &:before {
            max-width: 16px;
            min-width: 16px;
            height: 16px;
            border: 1px solid var(--color-created);
            border-radius: 2px;
            background: var(--color-white);
          }

          &:after {
            position: absolute;
            margin-top: -12px;
            top: 50%;
            left: 6px;
            width: 4px;
            height: 8px;
            border: 0 solid var(--color-white);
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) scaleY(0);
            transform-origin: center;
          }
        }
      }
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  p {
    padding: 0 0 20px;

    &:last-child {
      padding: 0;
    }
  }

  .br {
    border-radius: 15px;
  }

  .btn {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--color-transparent);
    color: var(--color-white);
    line-height: 0;
    text-decoration: none;

    &.h40 {
      height: 40px;
      border-radius: 20px;
    }

    &.h50 {
      height: 50px;

      @include media-max(md) {
        height: 40px;
      }

      @include media-max(sm) {
        height: 30px;
      }

      border-radius: 25px;
    }

    &.p24 {
      padding: 0 24px;
    }

    &.p32 {
      padding: 0 32px;
    }

    &.fs12 {
      font-size: 12px;
    }

    &.fs14 {
      font-size: 14px;
    }

    &.fs16 {
      font-size: 15px;

      @include media-max(md) {
        font-size: 11px;
      }
    }

    &.fw600 {
      font-weight: 600;
    }

    &.fw700 {
      font-weight: 700;
    }

    &.blue {
      border-color: var(--color-text-main);
      background: var(--color-text-main);
    }

    &.white {
      border-color: var(--color-white);
      background: var(--color-white);
      font-weight: 600;
      color: var(--color-text-main);

      &:hover {
        border-color: var(--color-text-main);
        background: var(--color-text-main);
        color: var(--color-white);
      }
    }

    &.transparent-gray {
      border: 1px solid var(--color-text-gray);
      background: var(--color-transparent);
      color: var(--color-text-gray);
    }

    &.transparent-green {
      border-color: var(--color-text-hover);
      color: var(--color-text-hover);

      &:hover {
        color: var(--color-white);
      }
    }

    &.transparent-dark {
      border-color: var(--color-text-main);
      color: var(--color-text-main);

      &:hover {
        background: var(--color-text-main);
        color: var(--color-white);
      }
    }

    &.greenfill {
      border-color: var(--color-text-hover);
      background: var(--color-text-hover);
      color: var(--color-white);

      &:hover {
        border-color: var(--color-text-main);
        background: var(--color-text-main);
      }
    }

    &:active {
      opacity: .8;
    }

    &:hover {
      border-color: var(--color-text-hover);
      background: var(--color-text-hover);
      color: var(--color-white);
    }
  }

  .ps__rail-x {
    display: none !important;
  }

  .ps__rail-y {
    width: 6px;
    opacity: 1;

    .ps__thumb-y {
      left: 0;
      right: 0;
      width: 100%;
      border-radius: 0;
    }
  }

  .selectbox {
    position: relative;

    button {
      justify-content: space-between;
      width: 100%;

      &.open {
        &:after {
          transform: rotate(-180deg);
        }
      }

      &:after {
        display: block;
        content: "";
      }
    }

    .hiddenbox {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1px solid var(--color-select-border);
      background: var(--color-white-80);
      backdrop-filter: blur(15px);
      opacity: 0;
      visibility: hidden;
      z-index: 1000;

      &.open {
        opacity: 1;
        visibility: visible;
      }

      ul {
        li {
          padding: 0 0 10px;

          &:last-child {
            padding: 0;
          }

          button {
            height: auto !important;
            padding: 0 20px;
            font-size: 14px !important;
            font-weight: 300 !important;
            line-height: 24px !important;
            letter-spacing: -0.28px;

            &:after {
              display: none;
            }
          }
        }
      }

      .ps__rail-y {
        right: 4px !important;
      }
    }
  }

  .swipernavs {
    display: flex;
    align-items: center;
    gap: 5px;



    button {
      width: 38px;
      height: 38px;

      &:hover {
        svg {
          use {
            stroke: var(--color-text-hover);
          }
        }
      }

      &:last-child {
        transform: rotate(180deg);
      }

      &.swiper-button-disabled {
        opacity: .5;
        cursor: default;
      }

      svg {
        use {
          stroke: var(--color-text-gray);
          stroke-width: 2;
        }
      }
    }
  }

  ul.swipernavs_slider {
    display: inline-flex;
    align-items: center;
    width: auto;
    height: 58px;
    border: 4px solid var(--color-text-main);
    border-radius: 30px;
    background: var(--color-text-main);

    @include media-max(md) {
      height: 45px;
    }

    &.inverse {
      border-color: var(--color-white);
      background: var(--color-white);

      li {
        height: 100%;

        button {
          height: 100%;
          border-radius: 26px;
          background: var(--color-white);
          color: var(--color-text-main);
          white-space: nowrap;

          &.swiper-button-disabled,
          &.active {
            background: var(--color-text-main);
            color: var(--color-white);
          }
        }
      }
    }

    li {
      height: 100%;

      button {
        height: 100%;
        padding: 0 32px;
        border-radius: 26px;
        background: var(--color-text-main);
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        color: var(--color-white);

        @include media-max(md) {
          font-size: 13px;
          padding: 0 10px;
        }

        &.swiper-button-disabled,
        &.active {
          background: var(--color-white);
          color: var(--color-text-main);
        }
      }
    }
  }

  .blurico {
    position: relative;
    width: 102px;
    max-width: 102px;
    min-width: 102px;
    height: 102px;
    margin: 0 28px 0 0;

    @include media-max(lg) {
      width: 78px;
      min-width: 55px;
      height: 78px;
    }

    &:before {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: linear-gradient(150.84deg, var(--color-animate-purple) 57.06%, var(--color-animate-purple-10) 145.73%);
      content: '';
    }

    .box {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: url(../img/icobg.svg);
      background-size: contain;
      overflow: hidden;

      span {
        position: absolute;
        top: 4px;
        left: 4px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border-radius: 50%;
        overflow: hidden;

        &:before,
        &:after {
          position: absolute;
          display: block;
          content: '';
        }

        &:before {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--color-bg);
        }

        &:after {
          bottom: -3px;
          right: -3px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          filter: blur(10px);
          background: linear-gradient(150.84deg, var(--color-animate-purple) 57.06%, var(--color-animate-purple-10) 145.73%);
        }
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 48px;
      height: 48px;
      margin: -24px 0 0 -24px;
    }
  }

  .gallerywithnav {
    position: relative;

    .swiper {
      &.main {
        width: 100%;
        height: 100%;

        .swiper-slide {
          &-active {
            .box {
              transform: scale(1) !important;
            }
          }

          .box {
            width: 100%;
            height: 100%;
            transform: scale(1.3);

            button {
              position: absolute;
              top: 20px;
              right: 20px;
              width: 38px;
              height: 38px;
              z-index: 1;

              &.active {
                svg {
                  &:first-child {
                    opacity: 0;
                    visibility: hidden;
                  }

                  &:last-child {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }

              svg {
                position: absolute;
                top: 0;
                left: 0;
                transform: scale(.8);

                &:hover {
                  transform: scale(1);
                }

                &:first-child {
                  opacity: 1;
                  visibility: visible;
                }

                &:last-child {
                  opacity: 0;
                  visibility: hidden;
                }

                use {
                  stroke: var(--color-white);
                  stroke-width: 1.5;
                }
              }
            }

            video,
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      &.thumb {
        position: absolute;
        bottom: 24px;
        left: 24px;
        width: 430px;
        max-width: calc(100% - 48px);
        padding: 30px 30px 20px;
        background: var(--color-white-70);
        backdrop-filter: blur(5px);
        z-index: 2001;

        @include media-max(lg) {
          left: 18px;
          bottom: 18px;
          max-width: calc(100% - 36px);
          padding: 24px 16px 16px;
        }

        &.dark {
          background: linear-gradient(45deg, var(--color-black-20) 0%, var(--color-black-50) 100%);

          .swiper-slide {

            h3,
            .desc {
              color: var(--color-white);
            }
          }

          .swipernavs {
            svg {
              use {
                stroke: var(--color-white);
              }
            }
          }
        }

        &.opacity {
          opacity: 0;
        }

        .swiper-slide {
          opacity: 0;
          transition: opacity .3s ease;

          &-active {
            opacity: 1;
          }

          .desc {
            padding: 8px 0 0;
            line-height: 22px;
          }

          a {
            margin: 28px 0 0;

            @include media-max(lg) {
              margin-top: 16px;
            }
          }
        }

        .swipernavs {
          position: absolute;
          bottom: 40px;
          right: 20px;
          height: 0;
          z-index: 1;
        }
      }
    }
  }

  ul.peroducts_list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px 16px;
    z-index: 1;

    @include media-max(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .card_product {
    a {
      display: block;

      &:hover {
        transform: scale(.97);
      }

      span {
        display: block;

        &.img {
          position: relative;
          padding: 40px;
          background: var(--color-white-80);
          overflow: hidden;

          span.label {
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 2px 8px 4px;
            border-radius: 4px;
            background: var(--color-text-hover);
            font-size: 10px;
            font-weight: 700;
            line-height: normal;
            color: var(--color-white);
          }
        }

        &.type {
          padding: 20px 0 0;
          margin: 0 0 -10px;
          font-weight: 300;
          line-height: 20px;

          @include media-max(md) {
            font-size: 11px;
            line-height: 16px;
          }
        }

        &.h3 {
          padding: 20px 0 0;

          @include media-max(md) {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }

  ul.accordion {
    &.block {
      padding: 24px 0 0;

      li {
        padding: 30px 0;
        border-bottom: 1px solid var(--color-black-10);

        &:last-child {
          padding: 30px 0;
          border-bottom: none;
        }

        ul {
          li {
            padding: 0 !important;
            border: 0;
          }
        }
      }
    }

    li {
      padding: 0 0 40px;

      &:last-child {
        padding: 0;
      }

      button {
        justify-content: space-between;
        width: 100%;

        &.active {
          padding: 0 0 24px;

          i {
            &:after {
              transform: rotate(0);
            }
          }
        }

        span {
          flex: 1 1 auto;
          display: block;
          text-align: left;
        }

        i {
          position: relative;
          display: block;
          max-width: 60px;
          min-width: 60px;
          height: 60px;
          margin: 0 0 0 20px;

          &:before,
          &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 30px;
            height: 2px;
            margin: -1px 0 0 -15px;
            background: var(--color-text-hover);
            content: "";
          }

          &:after {
            transform: rotate(-90deg);
          }
        }
      }

      ul {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 16px;
        max-height: 0;
        overflow: hidden;

        @include media-max(xl) {
          grid-template-columns: repeat(4, 1fr);
        }

        @include media-max(lg) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include media-max(md) {
          grid-template-columns: repeat(2, 1fr);
        }

        &.block {
          display: block;

          li {
            max-width: 1000px;
            background: var(--color-transparent);

            &:hover {
              background: var(--color-transparent);
              transform: scale(1);
            }

            .desc {
              * {
                font-size: 18px;
                font-weight: 300;
                line-height: 28px;

                @include media-max(md) {
                  font-size: 13px;
                  line-height: 20px;
                }
              }
            }
          }
        }

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          background: var(--color-white-60);
          transition: transform .7s;
          overflow: hidden;

          &:hover {
            background: var(--color-white);
            transform: scale(.97);

            img {
              filter: none;
              opacity: 1;
            }
          }

          img {
            max-width: 150px;
            filter: grayscale(100);
            mix-blend-mode: multiply;
            opacity: .5;
          }
        }
      }
    }
  }

  &.noscroll {
    height: 100Vh;
    overflow: hidden;

    .modal {
      overflow: auto;
    }
  }

  [data-swiper="certificates"] {
    overflow: visible !important;
  }

  .modalbox .gallery .swiper .swiper-slide .text {
    @media(max-width:600px) {
      top: -18px;
      position: relative;
    }
  }

  .app {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100Vh;
    padding: 115px 0 0;
    overflow-x: hidden;

    @include media-max(lg) {
      padding-top: 75px;
    }

    &.pharmacovigilance_page {
      main.content {
        .block11 {
          margin: 32px 0 0;
        }

        .block12 {
          margin: 85px 0 0;
        }

        .block13,
        .block14 {
          margin: 16px 0 0;
        }

        .block15 {
          margin: 100px 0 0;
        }
      }
    }

    &.about_page {
      main.content {

        .block17,
        .block18,
        .block19,
        .block21 {
          margin: 100px 0 0;
        }

        .block20 {
          margin: 118px 0 0;
        }
      }
    }

    &.news_page {
      main.content {
        .block22 {
          margin: 32px 0 0;
        }
      }
    }

    &.article_page {
      main.content {
        .block25 {
          margin: 32px 0 0;
        }
      }
    }

    &.contacts_page {
      .block23 {
        margin: 32px 0 0;
      }

      .block13 {
        margin: 13px 0 0;
      }
    }

    &.where_to_buy_page {
      .block24 {
        margin: 32px 0 0;
      }
    }

    &.collagen_products_page {
      .block25 {
        margin: 32px 0 0;
      }

      .block12:nth-child(3) {
        margin: 44px 0 0;
      }

      .block13:nth-child(4) {
        margin: 26px 0 0;

        .desc {
          padding: 40px;
        }
      }

      .block26 {
        margin: 26px 0 0;
      }

      .block27,
      .block12:nth-child(8),
      .block29 {
        margin: 100px 0 0;
      }

      .block28 {
        margin: 48px 0 0;
      }

      .block13:nth-child(9) {
        margin: 16px 0 0;
      }

      .block13:nth-child(11) {
        margin: 50px 0 0;
      }
    }

    &.catalog_page {
      main.content {
        .block25 {
          margin: 32px 0 0;
        }

        .block12 {
          margin: 40px 0 0;

          @include media-max(md) {
            margin-top: 20px;

            h2 {
              font-size: 19px;
            }

            .box img {
              min-height: 694px;
            }
          }
        }

        .block5 {
          margin: 16px 0 0;

          &:before {
            display: none;
          }
        }
      }
    }

    &.contract_production_page {
      main.content {
        .block25 {
          margin: 32px 0 0;
        }

        .block30 {
          margin: 16px 0 0;
        }

        .block31,
        .block40,
        .block9 {
          margin: 100px 0 0;
        }

        .block13 {
          margin: 16px 0 0;

          &:first-child {
            margin: 100px 0 0;
          }
        }
      }
    }

    &.cosmetic_products_line_page {
      main.content {
        .block25 {
          margin: 32px 0 0;
        }

        .block12 {
          margin: 36px 0 0;
        }

        .block26,
        .block33 {
          margin: 30px 0 0;
        }
      }
    }

    &.cosmetic_products_lines_page {
      main.content {
        .block25 {
          margin: 32px 0 0;
        }

        .block34 {
          margin: 36px 0 0;
        }
      }
    }

    &.cosmetic_products_page {
      main.content {
        .block25 {
          margin: 32px 0 0;
        }

        .block12 {
          margin: 36px 0 0;
        }

        .block26,
        .block33 {
          margin: 30px 0 0;
        }
      }
    }

    &.product_page {
      main.content {
        .block35 {
          margin: 40px 0 0;
        }

        .block36 {
          margin: 50px 0 0;
        }

        .block37 {
          margin: 90px 0 0;
        }
      }
    }

    &.article_page {
      main.content {
        .block39 {
          margin: 30px 0 0;
        }
      }
    }

    &.page_404 {

      main.content {
        padding: 0;
        padding-bottom: 0;
      }
    }

    &.search_page {
      main.content {
        .block42 {
          margin: 30px 0;
        }
      }
    }

    main.content {
      flex: 1 1 auto;
      padding-bottom: 80px;

      @include media-max(lg) {
        padding-bottom: 20px;
      }
    }

    .wrap {
      width: 100%;
      padding: 0 48px;

      @include media-max(lg) {
        padding: 0 12px;
      }

    }
  }

}

.header-contacts {
  display: none !important;
  z-index: 2222;
  bottom: 20px;
  left: 20px;
  position: fixed;

  .social {
    margin-top: 10px;
    gap: 10px;

    a {
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid var(--color-socials);
      border-radius: 10px;
    }

    svg {
      width: 50px;
      height: 50px;

      use {
        fill: var(--color-socials);
      }
    }
  }
}

.nav-switcher--active+.header-contacts {
  display: block !important;
}

.blurico-list {
  position: relative;

  &::before {
    position: absolute;
    left: 48px;
    top: 65px;
    width: 1px;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.1);
    display: block;
    content: '';

    @media(max-width:1200px) {
      display: none;
    }

  }

  .blurico {
    &::before {
      z-index: 1;
    }

    .box {
      z-index: 3;
    }

    &::after {
      position: absolute;
      left: 0;
      top: -10px;
      content: '';
      width: 100%;
      z-index: 0;
      height: calc(20px + 100%);
      background: #f5f5f5;
    }
  }
}

.block22 ul li a img {
  aspect-ratio: 4/3;
  object-fit: cover;
}

[data-swiper="news"] {
  :not(li) .img {



    img {
      max-height: 100%;
      object-fit: cover;
    }
  }

  .swiper-slide {
    &>a {

      .img {
        margin-top: 30px !important;
      }
    }

    height: auto;

    @media(min-width:1300px) {


      &:nth-child(odd) {
        max-width: calc(43vw - 48px);
      }

      &:nth-child(even) {
        max-width: calc(57vw - 48px - 20px);
      }
    }
  }

  .img {
    aspect-ratio: 4/3;
  }


  li .img {
    position: relative;
    margin-top: 0 !important;
    height: 172px !important;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

body {
  --inner-padding: 48px;

  @media (max-width: 1199px) {
    --inner-padding: 12px;
  }
}

.front-news {
  font-size: 14px;
  letter-spacing: -0.02em;
  margin-top: 120px;

  .h1 {
    margin-bottom: 40px;

    @media(max-width:1200px) {
      margin-bottom: 20px;
    }
  }

  h3 {
    margin-bottom: 10px;
  }

  @media(max-width:600px) {
    .swiper-wrapper {
      display: grid;
      gap: 20px;
      transform: none !important;
    }
  }

  .swiper-slide {
    height: auto;
  }

  p {

    font-size: 14px;
    line-height: 24px;

    b {
      font-weight: 800;
    }

    big {
      font-size: 18px;
      line-height: 28px;
    }
  }

  position: relative;
  z-index: 6;

  &__swiper {}

  &__slide-one {
    .front-news__item-img-wrap {
      margin-top: auto;
    }

    .front-news__item-img-wrap {
      img {
        @media(min-width:1440px) {
          aspect-ratio: 16/9;
        }
      }
    }

    .front-news__item {
      flex: 1;
    }
  }


  &__item {
    background: rgba(255, 255, 255, 0.8);
    padding: 24px;
    transition: transform .7s;

    &:hover {
      transform: scale(.97);
    }

    @media(max-width:600px) {
      padding: 16px;
    }
  }

  &__item-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

  }

  &__item-content {
    grid-area: content;
  }

  &__item-head {
    display: flex;
    margin-bottom: 30px;
    flex-direction: row;

    @media(max-width:992px) {
      margin-bottom: 10px;
    }
  }

  img {
    aspect-ratio: 4/3;
    object-fit: cover;
  }

  &__item-date {
    margin-right: auto;
    font-size: 18px;

    @media(max-width:992px) {
      font-size: 14px;
    }
  }

  &__item-type {
    color: #999999;
    font-size: 18px;

    @media(max-width:992px) {
      font-size: 14px;
    }
  }

  &__item-img-wrap {
    line-height: 0;
    grid-area: photo;
  }


  &__slide-mob,
  &__slide-one {
    display: grid !important;
    grid-template-rows: 1fr;
    height: initial;
    max-width: calc(50vw - 8px - var(--inner-padding));

    @media(min-width:1400px) {
      max-width: calc(43vw - 8px - var(--inner-padding));
    }

    @media(max-width:600px) {
      max-width: 100%;
      width: 100% !important;
    }

    .front-news__item {
      display: flex;
      flex-direction: column;
    }

    .front-news__item-content {
      margin-bottom: 10px;
    }

    .front-news__img-wrap {
      margin-top: auto;

      @media(max-width:992px) {
        margin-top: 10px !important;
        width: 100%;
      }

    }
  }

  &__slide-mob {
    display: none !important;



    @media(max-width:992px) {
      display: grid !important;
    }

    @media(max-width:600px) {
      max-width: 100%;
      width: 100% !important;
    }
  }


  &__slide-column {

    display: grid !important;
    gap: 20px;
    grid-template-rows: 1fr 1fr;
    height: initial;
    max-width: calc(50vw - 8px - var(--inner-padding));

    @media(min-width:1400px) {
      max-width: calc(57vw - 8px - var(--inner-padding));
    }



    @media(max-width:992px) {
      display: none !important;
    }

    .front-news__item {
      display: flex;
      flex-direction: row-reverse;
      gap: 26px;
    }

    .front-news__item-img-wrap {
      max-width: 266px;
      min-width: 266px;

      @media(min-width:1500px) {
        max-width: 40% !important;
        min-width: 40% !important;
      }

      img {
        width: 100%;

      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;


  }

  &__nav {
    display: flex;
    flex-direction: row;
    margin-right: auto;

    @media(max-width:600px) {
      display: none;
    }
  }

  &__prev,
  &__next {
    width: 38px;
    height: 38px;
    background: transparent;
    border: 0 solid;
    cursor: pointer;


    svg {
      width: initial !important;
      height: initial !important;
    }

    &.swiper-button-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover {
      path {
        stroke: var(--color-text-hover)
      }
    }
  }

  &__prev {
    margin-right: 5px;
  }

  &__next {}
}

body ul.accordion li ul li,
html ul.accordion li ul li {
  min-height: 160px;

  a {
    text-decoration: none;
  }
}

.article_page {
  .inner {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  }
}