.block17 {

  @include media-max(md) { margin-top: 32px !important;}
  .swiper {
    overflow: visible;
    ul.steps {
      display: flex;
      align-items: center;
      padding: 34px 0 0;

      @include media-max(lg) { overflow-x: scroll; }
      @include media-max(md) { padding-top: 18px; }

      li {
        button {
          height: 38px;
          padding: 0 16px;
          border-radius: 19px;
          background: var(--color-transparent);
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          &:hover {
            background: var(--color-text-hover);
            color: var(--color-white);
          }
          &.active {
            background: var(--color-text-main);
            color: var(--color-white);
          }
        }
      }
    }
    .swiper-wrapper {
      align-items: stretch;
      .swiper-slide {
        height: auto;
        padding: 74px 0 0;

        @include media-max(md) {
          &:last-child,
          &:nth-last-child(2) { display: none; }
        }
        @include media-max(sm) { &:nth-last-child(3) { display: none; } }

        .box {
          position: relative;
          height: 100%;
          padding: 35px 24px 47px;
          background: var(--color-white-80);

          @include media-max(md) { padding-top: 27px; }

          &:before,
          &:after {
            position: absolute;
            display: block;
            content: "";
          }
          &:before {
            top: -30px;
            left: 2px;
            width: calc(100% + 16px);
            height: 0;
            border-bottom: 1px dashed var(--color-dashed);
          }
          &:after {
            top: -41px;
            left: 0;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14' cy='14' r='12' fill='%23F5F5F5' stroke='%232E8647' stroke-width='2.79167'/%3E%3Ccircle cx='14' cy='14' r='6' fill='%232E8647'/%3E%3C/svg%3E%0A");
            background-size: 100%;
          }
          .title {
            font-size: 40px;
            font-weight: 500;
            color: var(--color-text-hover);
            line-height: normal;
            letter-spacing: -0.8px;
          }
          .desc {
            padding: 12px 0 0;
            line-height: 20px;
          }
        }
      }
    }
  }
}
