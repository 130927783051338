.block28 {
  .textbox {
    margin: 46px 0 0;
    padding: 60px 40px;
    background: var(--color-white-80);

    @include media-max(md) { padding: 30px 16px; }

    &.bg {
      background-image: url(../img/textbg.png);
      background-position: bottom center;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .title {
      padding: 0 0 20px;
      border-bottom: 1px solid var(--color-black-10);
    }
    img {
      width: 100%;
      margin: 20px 0 0;
    }
    .text {
      padding: 20px 0 0;
      font-weight: 300;
      p {
        padding: 0 0 20px;

        @include media-max(md) { font-size: 13px; }

        &:last-child {
          padding: 0;
        }
        &.green {
          font-weight: 600;
          color: var(--color-text-hover);
        }
      }
    }
    .h2 {
      padding: 0 0 20px;
      border-bottom: 1px solid var(--color-black-10);

      @include media-max(md) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
