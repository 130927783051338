.block19 {
  position: relative;
  z-index: 1;

  @include media-max(md) { margin-top: 30px !important; }

  .swipernavs {
    position: absolute;
    top: 14px;
    right: 0;
  }
  .swiper {
    padding: 20px 0 0;
    .swiper-wrapper {
      align-items: stretch;
      .swiper-slide {
        height: auto;
        padding: 30px 20px 80px;
        background: var(--color-white-80);

        @include media-max(md) { padding: 30px 16px 5px; }

        img {
          width: 67px;
          height: 67px;
        }
        .h3 { font-size: 22px; }
        .h3,
        .desc {
          padding: 20px 0 0;

          @include media-max(md) { padding-top: 23px; }
        }
      }
    }
  }
}
