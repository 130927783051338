:root {
  --color-white: #FFFFFF;
  --color-white-80: rgba(255, 255, 255, .8);
  --color-white-70: rgba(255, 255, 255, .7);
  --color-white-60: rgba(255, 255, 255, .6);
  --color-white-50: rgba(255, 255, 255, .5);
  --color-black: #000000;
  --color-black-70: rgba(0, 0, 0, .7);
  --color-black-50: rgba(0, 0, 0, .5);
  --color-black-20: rgba(0, 0, 0, .2);
  --color-black-10: rgba(0, 0, 0, .1);
  --color-black-07: rgba(0, 0, 0, .07);
  --color-bg: #F5F5F5;
  --color-text-main: #121720;
  --color-text-gray: #999999;
  --color-text-hover: #2E8647;
  --color-text-hover-06: rgba(46, 134, 71, 0.06);
  --color-created: #D9D9D9;
  --color-socials: #424242;
  --color-animate-green: #42C768;
  --color-animate-purple: #0F41C1;
  --color-animate-purple-10: rgba(15, 65, 193, .1);
  --color-animate-purple-40: rgba(15, 65, 193, .4);
  --color-gradient-gray: #F0F0F0;
  --color-dashed: #B5B5B5;
  --color-solid-40: rgba(153, 153, 153, .4);
  --color-select-border: #DCDCDC;
  --color-transparent: rgba(255, 255, 255, 0);
  --color-error: #C81818;
  --999999-silver: #999;
}
