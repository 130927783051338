.block21 {
  .items {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 49%);
    gap: 2%;
    padding: 100px 0;

    @include media-max(md) { display: block; }

    &:before {
      position: absolute;
      top: 0;
      left: -48px;
      width: calc(100% + 96px);
      height: 100%;
      border-radius: 15px;
      background: var(--color-white-80);
      content: "";
      z-index: 1;
    }
    .item {
      position: relative;
      z-index: 2;
      &:first-child {
        padding: 0;
        .text {
          padding: 30px 0 0;
          font-weight: 300;

          @include media-max(md) {
            padding-top: 13px;
            font-size: 16px;
            padding-bottom: 20px;
          }
        }
        img {
          transform: rotate(30deg);
          transition: none;

          @include media-max(md) { display: none; }
        }
      }
      &:last-child {
        .swiper {
          .swiper-slide {
            padding: 10px 0 0;
            ul {
              li {
                &:last-child { button { border: 0; } }
                button {
                  justify-content: space-between;
                  gap: 20px;
                  width: 100%;
                  padding: 30px 0;
                  border-bottom: 1px solid var(--color-solid-40);

                  @include media-max(md) { padding: 22px 0 9px; }

                  span {
                    display: block;
                    text-align: left;
                    letter-spacing: -.5px;

                    @include media-max(md) { font-size: 17px; }
                  }
                  svg {
                    max-width: 38px;
                    min-width: 38px;
                    height: 38px;
                    use {
                      fill: var(--color-text-hover);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
