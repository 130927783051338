.block37 {
  .swipernavs {
    position: absolute;
    top: 14px;
    right: 0;
  }

  .swiper {
    padding: 20px 0 0;
  }
}