.block2 {
  margin: 16px 0 0;
  position: relative;
  padding: 70px 40px 50px;
  background: var(--color-white-80);
  z-index: 1;

  @include media-max(lg) { padding: 50px 16px 42px; }

  ul {
    display: grid;
    gap: 190px;
    grid-template-columns: repeat(4, 1fr);
    padding: 44px 0 0;

    @include media-max(xl) { gap: 32px; }
    @include media-max(md) {
      grid-template-columns: repeat(2, 1fr);
      padding-top: 36px;
    }
    @include media-max(sm) { grid-template-columns: 1fr; }

    li {
      .label {
        margin: 0 0 35px;
        padding: 0 0 8px;
        border-bottom: 1px solid var(--color-black-10);
        font-weight: 300;
        color: var(--color-text-gray);

        @include media-max(lg) {
          font-size: 14px;
          margin-bottom: 13px;
        }
      }
      .number {
        font-size: 64px;
        font-weight: 500;
        line-height: 72px;
        letter-spacing: -4px;

        @include media-max(lg) { font-size: 50px; }
      }
      .desc {
        padding: 4px 0 0;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.1px;

        @include media-max(lg) {
          padding: 0 0 4px;
          font-size: 13px;
        }
      }
    }
  }
}
