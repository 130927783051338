.block39 {
  .mainimg {
    position: relative;
    overflow: hidden;
    z-index: 1;

    img {
      max-height: 600px;
      object-fit: cover;

      @include media-max(md) { max-height: 240px; }
    }
  }
  .textbox {
    position: relative;
    max-width: 1000px;
    padding: 50px 0 0;
    font-weight: 300;
    z-index: 1;
  }
  ul.gallery {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 50px 0 0;
    z-index: 1;

    @include media-max(md) { grid-template-columns: 1fr;  }

    img {
      max-height: 300px;
      object-fit: cover;

      @include media-max(md) { max-height: 200px; }
    }

    li { overflow: hidden; }
  }
  .description {
    position: relative;
    max-width: 1000px;
    padding: 50px 0 0;
    z-index: 1;
    ul {
      padding: 20px 0 0;
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 0 20px;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        &:last-child {
          padding: 0;
        }
        &:before {
          display: block;
          max-width: 25px;
          min-width: 25px;
          height: 25px;
          margin: -2px 0 0;
          background-image: url("data:image/svg+xml,%3Csvg width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66699 13L11.667 17.1667L18.3337 8.83337' stroke='%232E8647' stroke-width='2'/%3E%3C/svg%3E%0A");
          background-size: 100%;
          content: "";
        }
        &:last-child {
          padding: 0;
        }
      }
    }
    a.btn {
      margin: 20px 0 0;
    }
  }
}
