.block20 {
  position: relative;
  z-index: 1;

  @include media-max(md) { margin-top: 30px !important; }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 30px 0 0;

    @include media-max(md) { display: block; }

    li {
      @include media-max(md) { margin-bottom: 18px; }
      button {
        position: relative;
        display: block;
        min-height: 460px;
        padding: 38px 20px;
        background: var(--color-white);
        text-align: left;
        overflow: hidden;
        cursor: pointer;
        width: 100%;

        @include media-max(md) { min-height: auto; }

        &:hover { transform: scale(.97); }
        &:before {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 100%;
          height: 100%;
          background: url(../img/logo_about_bg.png) top right no-repeat;
          background-size: auto 100%;
          content: "";
        }
        .desc { padding: 10px 0 0; }
      }
    }
  }
}
