.block41 {
  .info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 0;
    text-align: center;
    overflow: hidden;
    .logo {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      width: 160px;
      height: 55px;

      @include media-max(md) {
        top: 22px;
        width: 96px;
        height: 33px;
      }
    }
    h1 {
      position: relative;
      z-index: 1;
      font-size: 48px;
      line-height: 60px;
      margin-top: 5px;
    }
    .text {
      position: relative;
      margin: 0 auto;
      font-weight: 300;
      z-index: 1;

      @include media-max(md) {
        font-size: 17px;
        margin-bottom: 6px;
      }
    }
    .btn {
      position: relative;
      margin-top: 30px;
      z-index: 100;

      @include media-max(md) { margin-top: 24px; }
    }
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: none;
      z-index: 0;
      height: 100%;
      object-fit: cover;
    }
  }
  .h3 {
    position: relative;
    z-index: 1;
    p {
      padding: 0 0 10px;
      &:last-child { padding: 0; }
      span { color: var(--color-text-hover); }
    }
  }
}
