.block12 {
  position: relative;
  overflow: hidden;

  &:has(.info) {
    background-color: red;

    @include media-max(md) {
      margin-bottom: 150px !important;
    }
  }

  @include media-max(md) {
    //   margin-top: 30px !important;
    overflow: visible;



    .gallerywithnav .swiper.thumb.dark {
      bottom: -80px;
    }
  }



  video,
  img {
    display: block;
    width: 100%;
    min-height: 500px;
    object-fit: cover;

    @include media-max(sm) {
      min-height: 500px;
    }
  }

  .info {
    position: absolute;
    bottom: 24px;
    left: 24px;
    max-width: 430px;
    padding: 30px;
    background: var(--color-white-80);
    backdrop-filter: blur(10px);
    z-index: 222;

    @include media-max(md) {
      left: 16px;
      right: 16px;
      bottom: -68px;
      padding: 30px 16px;
      max-width: none;
    }

    @include media-max(sm) {
      bottom: -138px;
    }

    &.dark {
      background: linear-gradient(45deg, var(--color-black-20) 0%, var(--color-black-50) 100%);

      .h3,
      .desc {
        color: var(--color-white);
      }
    }

    .desc {
      padding: 8px 0 0;
    }
  }

  .article {
    padding: 40px 0 0;
    overflow: hidden;

    @include media-max(lg) {
      padding-top: 0;
      margin-top: 20px;
    }

    .desc {
      position: absolute;
      bottom: 40px;
      left: 40px;
      max-width: 844px;
      font-size: 18px;
      line-height: 28px;
      color: var(--color-white);

      @include media-max(md) {
        font-size: 13px;
        bottom: 16px;
        left: 16px;
        line-height: 18px;
      }
    }
  }
}