.search-window {
  width: 100%;
  max-width: 1118px;
  margin: 0 auto;
  margin-top: 160px;

  @include media-max(lg) { width: 90%; }
}
.search-window-form {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-bottom: 18px;
  border-bottom: 2px solid var(--color-white);

  .search__field {
    flex: 1 0 auto;
    max-width: none;
    min-width: auto;
    padding: 0;
    border: none;
    outline: none;
    border-radius: 0;
    background: transparent;

    color: var(--color-white);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Manrope';
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: -1px;

    @include media-max(lg) { font-size: 42px; }
    @include media-max(md) {
      font-size: 34px;
      max-width: calc(100% - 100px);
    }
    @include media-max(sm) { font-size: 26px; }

    &::placeholder { color: var(--999999-silver); }
  }
  .search__btn {
    width: 50px;
    height: 50px;

    margin-left: 50px;

    border-radius: 25px;
    background: var(--color-white-60);
    box-shadow: 7px 10px 18px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);

    svg {
      width: 23px;
      height: 15px;
      use { fill: var(--color-text-hover); }
    }
  }

  &.active { .search__btn { background: var(--color-white); } }
}

.search-suggest {
  margin-top: 40px;
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0
    }
    a {
      display: inline-block;

      color: var(--999999-silver);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Manrope';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.3;

      @include media-max(lg) { font-size: 21px; }
      @include media-max(md) { font-size: 18px; }
      @include media-max(sm) { font-size: 16px; }

      span { color: var(--color-white); }
      &:hover {
        color: var(--color-text-hover);

        span { color: var(--color-text-hover); }
      }
    }
  }
}
.search-empty {
  margin-top: 40px;

  color: var(--color-white);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Manrope';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;

  @include media-max(lg) { font-size: 21px; }
  @include media-max(md) { font-size: 18px; }
  @include media-max(sm) { font-size: 16px; }
}
