.block18 {

  @include media-max(md) {
    margin-top: 30px !important;
  }

  ul {
    position: relative;
    display: grid;
    grid-template-columns: 58% 42%;
    gap: 16px;
    grid-template-areas: "one two" "three three";
    padding: 30px 0 0;
    z-index: 1;

    @include media-max(md) {
      display: block;
    }

    li {
      position: relative;
      overflow: hidden;

      @include media-max(md) {
        height: 340px;
        margin-bottom: 16px;
      }

      &:hover {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: var(--color-black-70);
        opacity: 0;
        visibility: hidden;
        content: "";
      }

      &:nth-child(1) {
        grid-area: one;
      }

      &:nth-child(2) {
        grid-area: two;
      }

      &:nth-child(3) {
        grid-area: three;
      }

      img {
        width: auto;
        min-width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .info {
        position: absolute;
        bottom: 40px;
        padding: 0 30px;
        color: var(--color-white);
        z-index: 1;

        &:hover {
          .text {
            max-height: 400px;
            opacity: 1;
          }
        }

        @include media-max(md) {
          padding: 0 16px;
          bottom: 15px;

          .h2 {
            font-size: 22px;
            line-height: 30px;
          }

          .text {
            font-size: 14px;
          }
        }

        .text {
          transition: max-height 1s, opacity 0.3s;
          max-height: 0;

          overflow: hidden;
        }
      }
    }
  }
}