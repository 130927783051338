.modalbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-socials);
  visibility: hidden;
  opacity: 0;
  z-index: 3000;

  &.modalbox_trans {
    background: rgba(0, 0, 0, 0.8);
  }

  &.dark {
    .box {
      background: var(--color-bg);
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &.open {
    button.close {
      right: 10px;
      left: auto;
      z-index: 11;
    }

    .box {
      left: 0;
    }

    .window {
      top: 0;
      opacity: 1;
      visibility: visible;

      @include media-max(lg) {
        top: 60px;
        max-width: 98%;
      }

      @include media-max(md) {
        top: 110px;
      }

      @include media-max(sm) {
        top: 130px;
      }

      .h2 {
        line-height: 1.3;

        @include media-max(md) {
          font-size: 28px;
        }

        @include media-max(sm) {
          font-size: 24px;
        }
      }

      .text {
        line-height: 1.3;

        @include media-max(md) {
          font-size: 16px;
        }

        @include media-max(sm) {
          font-size: 14px;
        }
      }
    }
  }

  button.close {
    position: absolute;
    top: 0;
    left: -120%;
    width: 60px;
    height: 60px;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 20px;
      height: 1px;
      margin: 0 0 0 -10px;
      background: var(--color-white);
      content: "";

      @include media-max(lg) {
        background: var(--color-black);
      }
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  .box {
    position: relative;
    left: -120%;
    max-width: 1052px;
    height: 100Vh;
    border-radius: 0 15px 15px 0;
    background: var(--color-white);

    .item {
      padding: 70px 48px 78px;

      @media(max-width:992px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &.about {
        .h3 {
          padding: 20px 0 0;
          color: var(--color-text-hover);
        }

        .info {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 100px;
          padding: 10px 0 0;
          margin: 28px 0 0;
          border-top: 1px solid var(--color-solid-40);

          @include media-max(lg) {
            grid-template-columns: 1fr;
          }

          .side {
            .label {
              padding: 40px 0 0;
              font-size: 14px;
              font-weight: 700;
              line-height: 22px;
            }

            ul {
              padding: 10px 0 0;

              &.grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 30px 16px;
                padding: 40px 0 0;

                li {
                  display: block;
                  padding: 0;

                  &:before {
                    display: none;
                  }

                  .label {
                    padding: 0;
                  }

                  .desc {
                    padding: 10px 0 0;
                    color: var(--color-text-main);
                  }
                }
              }

              li {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 0 0 10px;
                font-size: 14px;
                font-weight: 300;
                line-height: 24px;

                &:before {
                  display: block;
                  max-width: 25px;
                  min-width: 25px;
                  height: 25px;
                  margin: -2px 0 0;
                  background-image: url("data:image/svg+xml,%3Csvg width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66699 13L11.667 17.1667L18.3337 8.83337' stroke='%232E8647' stroke-width='2'/%3E%3C/svg%3E%0A");
                  background-size: 100%;
                  content: "";
                }

                &:last-child {
                  padding: 0;
                }
              }
            }

            a.btn {
              margin: 40px 0 0;
            }
          }
        }
      }

      &.contract_production {
        ul.list {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 40px 0 0;

          &+.h1 {
            padding: 90px 0 0;
          }

          li {
            display: flex;
            justify-content: space-between;
            gap: 16px;

            @media(max-width:992px) {
              flex-direction: column;
            }

            .info {
              padding: 30px 20px;
              background: var(--color-white-80);

              .h3 {
                color: var(--color-text-hover);
              }

              .desc {
                padding: 20px 0 0;
              }

              ul {
                display: flex;
                gap: 20px;
                padding: 20px 0 0;

                li {
                  display: block;

                  .value {
                    padding: 10px 0 0;
                    font-size: 14px;
                    font-weight: 800;
                    line-height: 20px;
                  }
                }
              }
            }

            .img {
              max-width: 302px;
              min-width: 302px;
              overflow: hidden;

              @media(max-width:992px) {
                max-width: 100%;

              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }

    .ps__rail-x {
      display: none;
    }

    .ps__rail-y {
      width: 6px;
      left: 0 !important;
      right: auto !important;
      opacity: 1;

      .ps__thumb-y {
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0;
      }
    }
  }

  .window {
    position: relative;
    top: 100px;
    max-width: 1080px;
    padding: 60px;
    margin: 40px 0;
    border-radius: 15px;
    background: var(--color-white);
    opacity: 0;
    visibility: hidden;

    button.close {
      top: 10px;
      left: auto !important;
      right: 10px;

      &:before,
      &:after {
        background: var(--color-text-main);
      }
    }

    .text {
      padding: 10px 0 0;
      font-weight: 300;
      color: var(--color-text-hover);
    }

    form {
      padding: 40px 0 0;

      .items {
        display: grid;
        grid-template-columns: 40% calc(60% - 16px);
        gap: 16px;

        @include media-max(lg) {
          display: block;
        }

        .item {
          &:last-child {
            .fieldset {
              height: 100%;
            }
          }

          .field {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0 0 10px;

            &:last-child {
              padding: 0;
            }

            textarea,
            input {
              border-color: var(--color-bg);
            }

            textarea {
              flex: 1 1 auto;
              max-height: 238px;
              min-height: 238px;
            }
          }
        }
      }

      .accept {
        padding: 20px 0 0;
      }

      .btn {
        margin: 20px 0 0;
      }
    }
  }

  .gallery {
    height: 100%;

    .lg-toolbar {
      opacity: 1;
    }

    .swiper {
      height: 100%;
      margin: 0 30px;

      .swiper-slide {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 40px 0 0;
        transform: scale(.9);
        opacity: .6;

        &-active {
          transform: scale(1);
          opacity: 1;

          .text {
            opacity: 1 !important;
          }
        }

        .img {
          overflow: hidden;
          margin-top: auto;

          img {
            width: 100%;
          }
        }

        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          margin: auto 0 0;
          font-size: 20px;
          line-height: 30px;
          color: var(--color-white);
          opacity: 0;
        }
      }
    }

    .info {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      height: 0;

      .steps {
        padding: 0 0 10px 20px;
        font-size: 16px;
        color: var(--color-white);
      }

      .navs {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0 20px 4px 0;

        .lg-icon {
          position: relative;
          left: auto;
          right: auto;
          bottom: auto;
          opacity: 1;
        }
      }
    }
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &.modalbox_trans {
    align-items: flex-start;
  }
}